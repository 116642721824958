/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1240px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1240px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1240px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1239px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1240px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1240px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.sidebar {
  order: 1;
  width: 26.25rem;
}

.accordions {
  margin-bottom: 20px;
}
.accordions h2.accordion-title {
  position: relative;
  left: 0;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
}
.accordions h2.accordion-title button {
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.04em;
  position: relative;
  margin: 0 0 10px 0;
  padding: 20px 82px 10px 0;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #e6e6e6;
  background: transparent;
  color: #333;
  text-align: left;
  font-weight: 700;
  font-family: "Barlow Condensed", "Arial Narrow", Arial, sans-serif;
}
.accordions h2.accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 80px;
  height: calc(100% + 2px);
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #fff;
}
.accordions h2.accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 32px;
  color: #cb3f14;
  content: "\f067";
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  font-family: FontAwesome;
  transform: translateY(-8px);
}
.accordions h2.accordion-title button:hover, .accordions h2.accordion-title button:focus {
  outline: 0;
  border: 0;
  border-bottom: 2px solid #cb3f14;
  background: transparent;
  cursor: pointer;
}
.accordions h2.accordion-title button:hover span, .accordions h2.accordion-title button:focus span {
  border-bottom: 2px solid #cb3f14;
}
.accordions h2.accordion-title.is-active button, .accordions h2.accordion-title.is-active button:focus {
  outline: 0;
  border: 0;
  border-bottom: 2px solid #e6e6e6;
  background: transparent;
  color: #333;
}
.accordions h2.accordion-title.is-active button span, .accordions h2.accordion-title.is-active button:focus span {
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #fff;
}
.accordions h2.accordion-title.is-active button span:after, .accordions h2.accordion-title.is-active button:focus span:after {
  color: #bfbfbf;
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}

.tabs {
  margin-bottom: 20px;
}
.tabs .tab-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}
.tabs .tab-list[role=tablist] {
  display: block;
}
.tabs .tab-list li {
  display: inline-block;
  margin: 0 2px 0 0 !important;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list li:before {
  content: none !important;
}
.tabs .tab-list li a {
  display: block;
  padding: 14px 40px 13px;
  border-bottom: 5px solid #cfcfcf;
  background: #e6e6e6;
  color: #333;
  text-decoration: none;
  letter-spacing: 0.004em;
  font-style: normal;
  font-size: 20px;
  font-family: Rift;
  line-height: 1em;
}
.tabs .tab-list li a:hover {
  border-bottom: 5px solid #ad3611;
  background: #cb3f14;
  color: #fff;
  text-decoration: none;
}
.tabs .tab-list li a[role=tab]:focus {
  border-bottom: 5px solid #ad3611;
  background: #cb3f14;
  color: #fff;
}
.tabs .tab-list li a[aria-selected] {
  border-bottom: 5px solid #ad3611;
  background: #cb3f14;
  color: #fff;
}
.tabs .tab-list li a[aria-selected]:hover {
  border-bottom: 5px solid #ad3611;
  background: #cb3f14;
  color: #fff;
}
.tabs [role=tabpanel] {
  padding: 50px 60px;
  background: #f2f2f2;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

table,
table.tablesaw {
  width: 100%;
  max-width: 100%;
  margin: 0 0 24px 0;
  padding: 0;
  empty-cells: show;
  border: 0;
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}

.tablesaw * {
  box-sizing: border-box;
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
  display: none;
}

@media only all {
  .tablesaw-stack {
    clear: both;
  }
  .tablesaw-stack td,
.tablesaw-stack th {
    display: block;
    text-align: left;
  }
  .tablesaw-stack tr {
    display: table-row;
    clear: both;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: inline-block;
    width: 30%;
    padding: 0 10px 0 0;
  }
  .tablesaw-stack th .tablesaw-cell-label-top,
.tablesaw-stack td .tablesaw-cell-label-top {
    display: block;
    margin: 6px 0;
    padding: 6px 0;
  }
  .tablesaw-stack .tablesaw-cell-label {
    display: block;
  }
  .tablesaw-stack tbody th.group {
    margin-top: -1px;
  }
  .tablesaw-stack th.group b.tablesaw-cell-label {
    display: none !important;
  }
}
@media (max-width: 39.9375em) {
  table.tablesaw-stack {
    margin: 0 -20px 24px -20px;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
  }
  table.tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    padding: 0 20px;
  }
  table.tablesaw-stack tbody tr:last-child {
    border-bottom: 1px solid gray;
  }
  table.tablesaw-stack thead {
    display: none;
  }
  table.tablesaw-stack tbody td,
table.tablesaw-stack tbody th {
    display: block;
    clear: left;
    width: 100%;
    padding: 0;
  }
  table.tablesaw-stack tbody td:last-child,
table.tablesaw-stack tbody th:last-child {
    border-bottom: 0;
  }
  table.tablesaw-stack td .tablesaw-cell-label {
    display: inline-block;
    padding: 8px 8px 8px 0;
    text-transform: uppercase;
    vertical-align: top;
    background: transparent;
    font-weight: 700;
  }
  table.tablesaw-stack td .tablesaw-cell-content {
    display: inline-block;
    max-width: 67%;
    padding: 8px 0 8px 8px;
  }
  table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-label,
table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-content {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  table.tablesaw-stack td:empty,
table.tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 40em) {
  .tablesaw-stack tr {
    display: table-row;
  }
  .tablesaw-stack td,
.tablesaw-stack th,
.tablesaw-stack thead td,
.tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
  .tablesaw-stack thead th {
    padding: 10px 18px 8px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
  }
  .tablesaw-stack thead th:last-child {
    border-right: 0;
  }
  .tablesaw-stack tbody tr td {
    padding: 12px 18px 10px;
  }
  .tablesaw-stack tbody tr td:last-child {
    border-right: 0;
  }
}
.flex-container {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1240px) {
  .flex-container.alignleft {
    width: 40%;
    float: left;
    margin: 0 15px 10px 0;
  }
  .flex-container.alignright {
    width: 40%;
    float: right;
    margin: 0 0 10px 15px;
  }
  .flex-container.aligncenter {
    width: 40%;
    margin: 0 auto 10px auto;
  }
}

.flex-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.content-form {
  position: relative;
  overflow: visible;
  margin-right: -10px;
  margin-left: -10px;
  /* recaptcha */
  /* small screens */
  /* medium screens */
  /* large screens */
}
.content-form h2,
.content-form .form-header,
.content-form .form-footer {
  padding-right: 10px;
  padding-left: 10px;
}
.content-form h2 p:empty,
.content-form .form-header p:empty,
.content-form .form-footer p:empty {
  margin: 0;
}
.content-form p {
  clear: both;
  padding-right: 10px;
  padding-left: 10px;
}
.content-form p:empty {
  display: none;
}
.content-form hr {
  clear: both;
  margin-left: 10px;
  width: calc(100% - 20px);
}
.content-form .form-footer {
  margin-top: 30px;
}
.content-form .validation-summary {
  display: none;
}
.content-form fieldset {
  margin: 0 0 30px 0;
  padding: 0;
  border: 0;
}
.content-form fieldset:last-of-type {
  margin-bottom: 0;
}
.content-form label {
  position: relative;
  display: inline-block;
  clear: both;
}
.content-form input[type=text],
.content-form input[type=password],
.content-form input[type=date],
.content-form input[type=datetime],
.content-form input[type=datetime-local],
.content-form input[type=month],
.content-form input[type=week],
.content-form input[type=email],
.content-form input[type=number],
.content-form input[type=search],
.content-form input[type=tel],
.content-form input[type=time],
.content-form input[type=url],
.content-form textarea,
.content-form select {
  position: relative;
  margin: 0 0 30px 0;
  max-width: 100%;
  width: 100%;
  appearance: none;
}
.content-form input[type=text]:active, .content-form input[type=text]:focus,
.content-form input[type=password]:active,
.content-form input[type=password]:focus,
.content-form input[type=date]:active,
.content-form input[type=date]:focus,
.content-form input[type=datetime]:active,
.content-form input[type=datetime]:focus,
.content-form input[type=datetime-local]:active,
.content-form input[type=datetime-local]:focus,
.content-form input[type=month]:active,
.content-form input[type=month]:focus,
.content-form input[type=week]:active,
.content-form input[type=week]:focus,
.content-form input[type=email]:active,
.content-form input[type=email]:focus,
.content-form input[type=number]:active,
.content-form input[type=number]:focus,
.content-form input[type=search]:active,
.content-form input[type=search]:focus,
.content-form input[type=tel]:active,
.content-form input[type=tel]:focus,
.content-form input[type=time]:active,
.content-form input[type=time]:focus,
.content-form input[type=url]:active,
.content-form input[type=url]:focus,
.content-form textarea:active,
.content-form textarea:focus,
.content-form select:active,
.content-form select:focus {
  outline: 0;
}
.content-form textarea {
  min-height: 120px;
  height: auto;
}
.content-form select::-ms-expand {
  display: none;
}
.content-form .error-msg {
  position: relative;
  top: -30px;
  margin-top: 0;
  max-width: 100%;
  color: red;
}
.content-form .required label:after {
  position: relative;
  top: -7px;
  margin-left: 2px;
  content: "\f069";
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 6px;
  font-family: FontAwesome;
}
.content-form .checkbox {
  overflow: hidden;
}
.content-form input[type=submit] {
  margin-left: 10px;
  border: 0;
}
.content-form .g-recaptcha {
  margin: 0 0 30px 0;
  padding-right: 10px;
  padding-left: 10px;
}
.content-form .sm-full {
  float: none;
  clear: none;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
}
.content-form .sm-half {
  float: left;
  clear: none;
  padding-right: 10px;
  padding-left: 10px;
  width: 50%;
}
.content-form .sm-third {
  float: left;
  clear: none;
  padding-right: 10px;
  padding-left: 10px;
  width: 33.333%;
}
.content-form .sm-two-thirds {
  float: left;
  clear: none;
  padding-right: 10px;
  padding-left: 10px;
  width: 66.666%;
}
.content-form .sm-quarter {
  float: left;
  clear: none;
  padding-right: 10px;
  padding-left: 10px;
  width: 25%;
}
.content-form .sm-three-quarters {
  float: left;
  clear: none;
  padding-right: 10px;
  padding-left: 10px;
  width: 75%;
}
.content-form .sm-last + div {
  clear: both;
}
@media screen and (min-width: 40rem) {
  .content-form .sm-last + div {
    clear: none;
  }
  .content-form .md-full {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  }
  .content-form .md-half {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 50%;
  }
  .content-form .md-third {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 33.333%;
  }
  .content-form .md-two-thirds {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 66.666%;
  }
  .content-form .md-quarter {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 25%;
  }
  .content-form .md-three-quarters {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 75%;
  }
  .content-form .md-last + div {
    clear: both;
  }
}
@media screen and (min-width: 64rem) {
  .content-form .sm-last + div {
    clear: none;
  }
  .content-form .md-last + div {
    clear: none;
  }
  .content-form .lg-full {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  }
  .content-form .lg-half {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 50%;
  }
  .content-form .lg-third {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 33.333%;
  }
  .content-form .lg-two-thirds {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 66.666%;
  }
  .content-form .lg-quarter {
    float: left;
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 25%;
  }
  .content-form .lg-three-quarters {
    clear: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 75%;
  }
  .content-form .lg-last + div {
    clear: both;
  }
}

.search-results {
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: normal;
}
.search-results .stats {
  margin: 30px 0;
}
.search-results .search-results-search {
  background: lightgray;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 30px;
}
.search-results .search-results-search input[name=search] {
  border: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 30px;
  padding: 0 8px;
  width: calc(100% - 118px);
  font-style: italic;
  float: left;
}
.search-results .search-results-search input[name=search]::-webkit-input-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]:-moz-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]::-moz-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]:-ms-input-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search button[name=search-submit] {
  display: inline-block;
  border: 0;
  padding: 0 8px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 30px;
  width: 114px;
  text-align: center;
  float: right;
  text-decoration: none;
}
.search-results .search-results-search button[name=search-submit]:hover, .search-results .search-results-search button[name=search-submit]:focus {
  text-decoration: none;
}
.search-results .results {
  position: relative;
  padding-top: 20px;
}
.search-results .results:before {
  content: "";
  background: gray;
  position: absolute;
  top: -16px;
  width: 100%;
  height: 1px;
}
.search-results .pagination {
  border-top: 1px solid gray;
}
.search-results .pagination > td {
  padding: 16px 0 0 0;
}
.search-results .pagination span,
.search-results .pagination a {
  background: lightgray;
  color: gray;
  display: inline-block;
  text-decoration: none;
  padding: 3px 6px;
  border: 1px solid gray;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
}
.search-results .pagination a {
  color: blue;
}
.search-results .pagination a:visited {
  color: blue;
}
.search-results .pagination a:hover, .search-results .pagination a:focus {
  color: red;
}
.search-results th[scope=col] {
  display: none;
}
.search-results tr:nth-child(n+2):not(.pagination) > td {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  padding-bottom: 20px;
}
.search-results tr:nth-child(n+2):not(.pagination) > td a {
  display: block;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  letter-spacing: 0.0625em;
  font-weight: 400;
}

.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 640px) {
  .two-column,
.three-column,
.four-column,
.five-column,
.six-column {
    display: flex;
    margin: 0 10px;
    flex-wrap: wrap;
  }
}
.two-column .col,
.three-column .col,
.four-column .col,
.five-column .col,
.six-column .col {
  width: 100%;
}

@media (min-width: 640px) {
  .two-column .col {
    padding: 0 10px 10px 10px;
    width: 50%;
  }
}

@media (min-width: 640px) {
  .three-column .col {
    padding: 0 10px 10px 10px;
    width: 50%;
  }
}
@media (min-width: 1240px) {
  .three-column .col {
    width: 33.3%;
  }
}

@media (min-width: 640px) {
  .four-column .col {
    padding: 0 10px 10px 10px;
    width: 50%;
  }
}
@media (min-width: 1240px) {
  .four-column .col {
    width: 25%;
  }
}

@media (min-width: 640px) {
  .five-column .col {
    padding: 0 10px 10px 10px;
    width: 50%;
  }
}
@media (min-width: 1240px) {
  .five-column .col {
    width: 20%;
  }
}

@media (min-width: 640px) {
  .six-column .col {
    padding: 0 15px 15px 15px;
    width: 50%;
  }
}
@media (min-width: 1240px) {
  .six-column .col {
    width: 16.6666666667%;
  }
}

.about-us .main-content > h3 {
  margin-bottom: 3.125rem;
  padding-bottom: 0.9375rem;
  background: transparent url(/themes/CRN_Location/assets/dist/images/heading-line-border.gif) repeat-x bottom left;
}

.our-team {
  display: flex;
  max-width: 880px;
  flex-wrap: wrap;
}
.our-team .team-member {
  overflow: hidden;
  width: 100%;
}
@media screen and (min-width: 900px) {
  .our-team .team-member {
    margin: 0 1.6875rem 2.5rem;
    width: calc(50% - 45px);
  }
  .our-team .team-member:nth-child(2n) {
    margin-right: 0;
  }
  .our-team .team-member:nth-child(2n-1) {
    margin-left: 0;
  }
}
@media screen and (min-width: 1240px) {
  .our-team .team-member {
    width: calc(33% - 45px);
  }
  .our-team .team-member:nth-child(2n) {
    margin-right: 1.6875rem;
  }
  .our-team .team-member:nth-child(2n-1) {
    margin-left: 1.6875rem;
  }
  .our-team .team-member:nth-child(3n) {
    margin-right: 0;
  }
  .our-team .team-member:nth-child(3n-2) {
    margin-left: 0;
  }
}
@media screen and (min-width: 1400px) {
  .our-team .team-member {
    width: calc(25.5% - 45px);
  }
  .our-team .team-member:nth-child(3n) {
    margin-right: 1.6875rem;
  }
  .our-team .team-member:nth-child(3n-2) {
    margin-left: 1.6875rem;
  }
  .our-team .team-member:nth-child(4n) {
    margin-right: 0;
  }
  .our-team .team-member:nth-child(4n-3) {
    margin-left: 0;
  }
}
.our-team .team-member .profile-pic {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
}
.our-team .team-member .profile-pic:after {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 6;
  display: block;
  width: 25%;
  height: 7px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: #c7c7c7;
  content: "";
}
.our-team .team-member .profile-pic:before {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 7px;
  border-top: 2px solid #fff;
  background: #e6e6e6;
  content: "";
}
.our-team .team-member .profile-pic img {
  position: static;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 150%;
}
.our-team .team-member h3.member-name {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
  line-height: 0.8em;
}
.our-team .team-member h3.member-name a {
  font-size: 1.375rem;
  line-height: 1.375rem;
  letter-spacing: 0.001em;
  color: #1a6593;
  text-decoration: none;
  font-weight: bold;
  font-family: "Source Sans 3", "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  line-height: 0.8em;
}
.our-team .team-member h3.member-name a:after {
  content: none;
}
.our-team .team-member .member-title {
  font-size: 1rem;
  line-height: 1.125rem;
  letter-spacing: 0.1em;
  color: #666;
  font-family: "Barlow Condensed", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
}

.network-consulting .team-member {
  display: none;
}
.network-consulting .team-member[data-department*="Network Consulting & Retreat Planning"] {
  display: block;
}

.property-management .team-member {
  display: none;
}
.property-management .team-member[data-department*="On-Site Property Management & Conference Services"] {
  display: block;
}

.profile-detail {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1240px) {
  .profile-detail {
    flex-wrap: nowrap;
  }
}
.profile-detail:after {
  display: table;
  clear: both;
  content: "";
}
.profile-detail .profile-left {
  margin-right: 60px;
  margin-left: 0;
  max-width: 100%;
}
@media (min-width: 640px) {
  .profile-detail .profile-left {
    max-width: 50%;
  }
}
@media (min-width: 1240px) {
  .profile-detail .profile-left {
    min-width: 35%;
  }
}
@media (min-width: 1341px) {
  .profile-detail .profile-left {
    max-width: 350px;
    flex-grow: 10;
  }
}
@media (min-width: 1341px) {
  .profile-detail .profile-right {
    flex-shrink: 10;
  }
}
.profile-detail img.profile-image {
  margin: 0;
  width: 100%;
}
.profile-detail h3.name {
  margin-top: 10px;
}

.btn.btn-primary.our-team {
  float: right;
  padding: 20px 40px;
  width: auto;
}

@media screen and (min-width: 1240px) {
  .follow-us {
    float: right;
    margin-top: -110px;
  }
}
@media screen and (min-width: 1230px) {
  .follow-us {
    float: none;
    margin: 0;
  }
}
.follow-us h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 0.001em;
  text-transform: uppercase;
  font-family: "Barlow Condensed", "Arial Narrow", Arial, sans-serif !important;
}
.follow-us .follow-form {
  max-width: none;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .follow-us .follow-form {
    max-width: 300px;
  }
}
.follow-us .follow-form label {
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.001em;
  display: block;
  margin-top: 25px;
  margin-bottom: 3px;
  color: #333;
  font-weight: 700;
  font-family: "Source Sans 3", "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
}
.follow-us .follow-form input {
  display: inline-block;
  float: none;
  padding: 0 0.75rem;
  width: 100%;
  height: 2.1875rem;
  border: 0;
  border-radius: 5px;
  background: #f2f2f2;
  color: #666;
  vertical-align: middle;
  line-height: 100%;
}
.follow-us .follow-form input.last-input {
  margin-right: 10px;
  width: calc(100% - 70px);
}

.subpage .main-content .newsletter-header .fa {
  margin-right: 5px;
}
.subpage .main-content .newsletter-signup {
  position: relative;
  margin-bottom: 90px;
  padding: 30px 0;
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  text-align: center;
}
.subpage .main-content .newsletter-signup:before, .subpage .main-content .newsletter-signup:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
  background: transparent url("../images/heading-line-border.gif") repeat-x center left;
  content: "";
}
.subpage .main-content .newsletter-signup:after {
  top: auto;
  bottom: 0;
}
.subpage .main-content .newsletter-signup h3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin: 0 !important;
  color: #333;
  font-weight: 600;
}
.subpage .main-content .newsletter-signup hr {
  margin: 30px 0;
}
.subpage .main-content .newsletter-signup .signup-form {
  display: flex;
  padding: 0 25px;
}
.subpage .main-content .newsletter-signup .signup-form .form-field {
  margin-right: 25px;
  width: 33%;
  text-align: left;
}
.subpage .main-content .newsletter-signup .signup-form .form-field label {
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.001em;
  margin-bottom: 3px;
  color: #333;
  font-weight: 700;
  font-family: "Source Sans 3", "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
}
.subpage .main-content .newsletter-signup .signup-form .form-field input {
  padding: 10px;
  background: #f2f2f2;
}
.subpage .main-content .newsletter-signup .follow-btn {
  position: relative;
  display: inline-block;
  margin-left: 35px;
  width: 60px;
  height: 40px;
  border-radius: 0 10px 10px 0;
  background: #e6e6e6;
  color: #244a74;
  vertical-align: middle;
  letter-spacing: normal;
  font-size: 1.5rem;
  line-height: 2.25rem;
  align-self: flex-end;
}
.subpage .main-content .newsletter-signup .follow-btn .fa-angle-right {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#mc_embed_signup,
#mc_embed_signup input,
#mc_embed_signup textarea,
#mc_embed_signup select,
#mc_embed_signup button {
  font-family: "Source Sans 3", "Gill Sans", "Gill Sans MT", Calibri, sans-serif !important;
}
#mc_embed_signup[type=submit],
#mc_embed_signup input[type=submit],
#mc_embed_signup textarea[type=submit],
#mc_embed_signup select[type=submit],
#mc_embed_signup button[type=submit] {
  font-family: "Barlow Condensed", "Arial Narrow", Arial, sans-serif !important;
}

.subpage .main-content .plan-event {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  border-radius: 25px;
  text-align: center;
}
@media screen and (min-width: 640px) {
  .subpage .main-content .plan-event {
    display: flex;
    min-height: 175px;
    width: 100%;
  }
}
.subpage .main-content .plan-event .title-container {
  position: relative;
}
@media screen and (min-width: 640px) {
  .subpage .main-content .plan-event .title-container {
    overflow: hidden;
    min-width: 270px;
  }
}
@media screen and (min-width: 640px) {
  .subpage .main-content .plan-event .content-container {
    overflow: hidden;
    padding-bottom: 30px;
    border: 2px solid #e6e6e6;
    border-left: 0;
    border-radius: 0 25px 25px 0;
    text-align: left;
  }
}
.subpage .main-content .plan-event img {
  margin: 0;
  width: 100%;
}
@media screen and (min-width: 640px) {
  .subpage .main-content .plan-event img {
    width: auto;
    height: 100%;
  }
}
.subpage .main-content .plan-event .calendar-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 60px;
  transform: translateY(-90%) translateX(-50%);
}
.subpage .main-content .plan-event .plan-content {
  margin-top: -8px;
  padding: 20px 25px 40px;
  border-right: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
}
@media screen and (min-width: 640px) {
  .subpage .main-content .plan-event .plan-content {
    padding-top: 40px;
    padding-bottom: 15px;
    padding-left: 40px;
    border: 0;
  }
}
.subpage .main-content .plan-event h3 {
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.04em;
  position: absolute;
  top: 50%;
  margin: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-family: "Barlow Condensed", "Arial Narrow", Arial, sans-serif, sans-serif;
  transform: translateY(50%);
}
.subpage .main-content .plan-event p {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin: 0;
  font-style: italic;
  font-family: "Source Sans 3", "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
}
.subpage .main-content .plan-event .btn.btn-primary {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -10%;
  padding: 20px 0;
  width: 120%;
  line-height: 15px;
}
@media screen and (min-width: 640px) {
  .subpage .main-content .plan-event .btn.btn-primary {
    display: inline-block;
    margin: 0 40px;
    padding: 15px 35px;
    width: auto;
  }
}

.subpage article.sidebar .plan-event {
  position: relative;
  overflow: hidden;
  margin: 90px auto 0;
  width: 100%;
  border-radius: 25px;
  text-align: center;
}
@media (min-width: 1240px) {
  .subpage article.sidebar .plan-event {
    width: calc(100% - 120px);
  }
}
.subpage article.sidebar .plan-event .calendar-icon {
  position: absolute;
  top: 20px;
  left: 50%;
  width: 70px;
  height: 60px;
  transform: translateX(-50%);
}
.subpage article.sidebar .plan-event .plan-content {
  margin-top: -8px;
  padding: 20px 25px 40px;
  border-right: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
}
.subpage article.sidebar .plan-event h3 {
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.04em;
  position: absolute;
  top: 93px;
  margin: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-family: "Barlow Condensed", "Arial Narrow", Arial, sans-serif, sans-serif;
}
.subpage article.sidebar .plan-event p {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin: 0;
  font-style: italic;
  font-family: "Source Sans 3", "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
}
.subpage article.sidebar .plan-event img {
  max-height: 150px;
  width: 100%;
  object-fit: cover;
}
.subpage article.sidebar .plan-event .btn.btn-primary {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -10%;
  padding: 20px 0;
  width: 120%;
  line-height: 15px;
}

.subpage .main-content h3.recreation-title {
  margin-bottom: 35px;
  padding-bottom: 20px;
  border-bottom: 2px solid #e6e6e6;
}

@media screen and (min-width: 640px) {
  .recreation-content-container {
    display: flex;
  }
}
@media screen and (min-width: 640px) {
  .recreation-content-container .recreation-content {
    margin-right: 80px;
    width: 100%;
  }
}
.recreation-content-container .recreation-content .album {
  float: right;
}

.image-gallery {
  position: relative;
  height: 240px;
}

.main-content .album {
  display: inline-block;
  margin: 0;
}
.main-content .album .photos {
  position: relative;
  display: flex;
  width: 270px;
  height: 240px;
  justify-content: flex-start;
}
.main-content .album .photos:before {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 12;
  width: 30px;
  height: 25px;
  background: transparent url("/themes/CRN_Location/assets/dist/images/gallery-icon.png") top left no-repeat;
  background-size: 100%;
  content: "";
}
.main-content .album .photos:after {
  position: absolute;
  right: 0;
  bottom: -6px;
  z-index: 2;
  width: 25%;
  height: 5px;
  border-left: 1px solid #fff;
  background-color: #c5c5c5;
  content: "";
}
.main-content .album .photos .photo {
  margin: 0;
  width: 50%;
  height: 120px;
}
.main-content .album .photos .photo:nth-child(1n+5) {
  display: none;
}
.main-content .album .photos .photo a,
.main-content .album .photos .photo img {
  position: absolute;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.gallery-overlay-text {
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.04em;
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 3;
  color: #fff;
  text-transform: uppercase;
  font-family: "Barlow Condensed", "Arial Narrow", Arial, sans-serif;
}

.album {
  margin: 0;
  height: 240px;
}
.album:before {
  position: absolute;
  top: -6px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #e6e6e6;
  content: "";
}
.album:after {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #e6e6e6;
  content: "";
}

.gallery > h2,
.gallery > p,
.album > h2,
.album > p {
  display: none !important;
}
.gallery .photos .photo img,
.gallery .photos .photo .image,
.album .photos .photo img,
.album .photos .photo .image {
  margin: 0;
}